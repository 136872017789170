import { Company } from "../../../types/company";


const parseApiCompany = (company: any): Company => {

  return {
    name: company.name,
    banner: company.banner?.url,
    domains: company.domains?.map(({ domain }: { domain: string }) => domain) || [],
    template: company.template.code,
    logo: company.logo?.url,
    email: company.email,
    aboutUs: company.aboutUs,
    termsAndConditions: company.termsAndConditions,
    legalInformation: company.legalInformation,
    privacyPolicy: company.privacyPolicy,
    shippingDetails: company.shippingDetails,
    refunds: company.refunds,
    socials: company.social_networks || []
  }
}

const parseApiCompanyList = (stores: any[]): Company[] => stores.map(parseApiCompany)

export default parseApiCompany;
export { parseApiCompanyList };