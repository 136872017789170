import { Store } from "../../../types/store";
import configuration from "../../../config/configuration";

const { isProduction } = configuration

const parseApiStore = (store: any): Store => {

  const domains = store.domains?.map(({ domain }: { domain: string }) => domain) || [];
  const navToDomain = !!domains[0] && `https://${domains[0]}`;
  const navToIdentifier = `/${store.identifier}/categories?new-http=true`
  return {
    name: store.name,
    navTo: isProduction ? (navToDomain || navToIdentifier) : navToIdentifier,
    banner: store.banner?.url,
    image: store.image?.url,
    favicon: store.favicon?.url,
    description: store.description,
    email: store.email,
    identifier: store.identifier,
    id: store.id,
    template: store.template.code,
    domains: domains,
    aboutUs: store.aboutUs,
    shippingDetails: store.shippingDetails,
    refunds: store.refunds,
    socials: store.social_networks || [],
    stripeAccountId: store.stripeAccountId
  }
}

const parseApiStoreList = (stores: any[]): Store[] => stores.map(parseApiStore)

export default parseApiStore;
export { parseApiStoreList };