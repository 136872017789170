import {
  CHANGE_SESSION,
  REMOVE_PRODUCT_FROM_CART,
  REMOVE_ALL_PRODUCTS_FROM_CART,
  ADD_PRODUCT_TO_CART
} from "./actions";
import { Session, SessionProduct } from "../../../types/session";
import compareSessionProduct from "../../../utils/compareSessionProduct";
import getCartCopy from "../../../utils/getCartCopy";

const initialState: Session = {
  store: null,
  company: null,
  cart: []
};

const state = (state = initialState, action: any): Session => {
  switch (action.type) {
    case CHANGE_SESSION:
      return { ...state, ...action.payload };
    case ADD_PRODUCT_TO_CART: {
      const rProduct = action.payload as SessionProduct
      const cart = getCartCopy(state.cart)
      const cProductIndex = cart.findIndex((sProduct) => compareSessionProduct(sProduct, rProduct))
      if (cProductIndex >= 0) cart[cProductIndex].quantity += rProduct.quantity
      else cart.push(rProduct)
      return { ...state, cart };
    }
    case REMOVE_PRODUCT_FROM_CART: {
      const rProduct = action.payload as SessionProduct
      const cart = getCartCopy(state.cart)
      const cProductIndex = cart.findIndex((sProduct) => compareSessionProduct(sProduct, rProduct))
      if (cProductIndex >= 0) {
        if(cart[cProductIndex].quantity > rProduct.quantity) cart[cProductIndex].quantity -= rProduct.quantity
        else cart.splice(cProductIndex, 1)
      }
      return { ...state, cart };
    }
    case REMOVE_ALL_PRODUCTS_FROM_CART:
      return { ...state, cart: [] };
    default:
      return state;
  }
};

export default state;
