import React, { Suspense, useMemo } from "react";
import { Session } from "../types/session";
import { useSelector } from "@libraries/redux-state-provider-and-persister";
import { Template } from "../types/template";
import LoadingPage from "../components/LoadingPage";
import { Slide, ToastContainer } from "react-toastify";

const CIndexSunshine = React.lazy(() => import('./sunshine/CIndex'));
const SIndexSunshine = React.lazy(() => import('./sunshine/SIndex'));

const templates: Record<Template, any> = {
  sunshine: {
    company: CIndexSunshine,
    store: SIndexSunshine
  }
}

const Index = () => {
  const { company, store } = useSelector(({ session }: { session: Session }) => session)
  // useStyling(colors)
  const Template = useMemo(() => {
    if (!!store) return templates[store.template].store
    if (!!company) return templates[company.template].company
    return LoadingPage;
  }, [store, company])

  return (
    <Suspense fallback={<LoadingPage/>}>
      <ToastContainer
        position="bottom-right"
        theme="colored"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        transition={Slide}
      />
      <Template/>
    </Suspense>
  );
}

export default Index;