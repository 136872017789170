import React from "react";
import { Rings } from "react-loader-spinner";

const LoadingPage = () => {
  return (
      <Rings
        height="80"
        width="80"
        color="#495057"
        radius="6"
        visible={true}
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%)"
        }}
        ariaLabel="rings-loading"
      />
  );
}

export default LoadingPage;