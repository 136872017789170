import { Session, SessionProduct } from "../../../types/session";

export const CHANGE_SESSION = "CHANGE_SESSION";
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const REMOVE_PRODUCT_FROM_CART = "REMOVE_PRODUCT_FROM_CART";
export const REMOVE_ALL_PRODUCTS_FROM_CART = "REMOVE_ALL_PRODUCTS_FROM_CART";

type ActionType =
  "ADD_PRODUCT_TO_CART"
  | "REMOVE_PRODUCT_FROM_CART"
  | "CHANGE_SESSION"
  | "REMOVE_ALL_PRODUCTS_FROM_CART"

type Action<payload = any> = {
  type: ActionType,
  payload: payload
}

export const changeSession = (session: Session): Action<Session> => ({
  type: CHANGE_SESSION,
  payload: session
});

export const addProductToCart = (product: SessionProduct): Action<SessionProduct> => ({
  type: ADD_PRODUCT_TO_CART,
  payload: product
});

export const removeProductFromCart = (product: SessionProduct): Action<SessionProduct> => ({
  type: REMOVE_PRODUCT_FROM_CART,
  payload: product
});

export const removeAllProductsFromCart = (): Action => ({
  type: REMOVE_ALL_PRODUCTS_FROM_CART,
  payload: undefined
});