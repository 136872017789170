import RestApi from "@libraries/api";
import configuration from "../config/configuration"
import { parseStrapi4Response } from "@libraries/strapi-4-utils";

const { apiUrl, imageUrl } = configuration;

const Api = new RestApi({
  path: apiUrl,
  processResponse: ({ data }) => parseStrapi4Response(data, imageUrl)
})

export const defaultCompanyPopulate = ["template", "domains", "banner", "logo", "social_networks"]
export const defaultStoresPopulate = ["banner", "image", "logo", "template", "domains", "social_networks", "favicon"]
export const defaultCategoriesPopulate = ["banner", "image"]
export const defaultProductsPopulate = ["images", "category", "product_options.option.property", "product_options.option.icon"]
export const defaultOptionsPopulate = ["property"]

const getContext = (params: Record<string, any> = {}) => {
  return Api.get("/context", {
    params: {
      ...params,
      "company:populate": defaultCompanyPopulate,
      "store:populate": defaultStoresPopulate
    }
  })
};

const contextRequests = {
  get: getContext
}

const getCompany = (params: Record<string, any> = {}) => {
  return Api.get("/company", { params: { ...params, populate: defaultCompanyPopulate } })
};

const companyRequests = {
  get: getCompany
}

const getStores = (params: Record<string, any> = {}) => {
  return Api.get("/stores", { params: { ...params, populate: defaultStoresPopulate } })
}

const storesRequests = {
  getList: getStores
}

const getCategories = (params: Record<string, any> = {}) => {
  return Api.get("/categories", { params: { ...params, populate: defaultCategoriesPopulate } })
}

const categoriesRequests = {
  getList: getCategories
}

const getProducts = (params: Record<string, any> = {}) => {
  return Api.get("/products", { params: { ...params, populate: defaultProductsPopulate } })
}

const productsRequests = {
  getList: getProducts
}

const getOptions = (params: Record<string, any> = {}) => {
  return Api.get("/options", { params: { ...params, populate: defaultOptionsPopulate } })
}

const optionsRequests = {
  getList: getOptions
}

const sendEmail = (body: Record<any, any>, params: Record<string, any> = {}) => {
  return Api.post("/email", body, { params: { ...params } })
}

const emailRequests = {
  send: sendEmail
}

const createSale = (body: Record<any, any>, params: Record<string, any> = {}) => {
  return Api.post("/sales", body, { params: { ...params } })
}

const salesRequests = {
  create: createSale
}

export default Api
export {
  companyRequests,
  storesRequests,
  contextRequests,
  categoriesRequests,
  productsRequests,
  optionsRequests,
  emailRequests,
  salesRequests
}