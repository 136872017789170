import { SessionProduct } from "../types/session";

const compareSessionProduct = (productA: SessionProduct, productB: SessionProduct): boolean => {
  const isSameId = productA.id === productB.id;
  const sortedOptionsA = [...productA.options].sort((a, b) => a - b)
  const sortedOptionsB = [...productB.options].sort((a, b) => a - b)
  const isSameOptions = sortedOptionsA.toString() === sortedOptionsB.toString()
  return isSameId && isSameOptions
}

export default compareSessionProduct