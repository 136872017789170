import React from 'react';
import { contextRequests } from "./api/Api";
import { AfterRehydrate, PersistReducers } from "@libraries/redux-state-provider-and-persister";
import { changeSession } from "./redux/reducers/session/actions";
import parseApiStore from "./utils/parse/api/parseApiStore";
import ReduxStateProviderAndPersister from "@libraries/redux-state-provider-and-persister";
import storePersistConfig from "./redux/reducers/session/persistConfig";
import storeState from "./redux/reducers/session/state";
import parseApiCompany from "./utils/parse/api/parseApiCompany";
import DynamicTemplate from "./templates/Index";
import { Session } from "./types/session";

const stores: PersistReducers = {
  session: {
    persistConfig: storePersistConfig,
    baseReducer: storeState
  }
}

const afterRehydrate: AfterRehydrate = async (reduxStore) => {
  const state = reduxStore.getState();
  const session: Session = state.session;
  try {
    const [{ company: apiCompany, store: apiStore }] = await contextRequests.get({ url: window.location.href });
    const company = parseApiCompany(apiCompany)
    const store = !!apiStore ? parseApiStore(apiStore) : null
    reduxStore.dispatch(changeSession({ ...session, store, company }));
  } catch (e) {
    console.log(e)
  }
}

function App() {
  return (
    <ReduxStateProviderAndPersister stores={stores} afterRehydrate={afterRehydrate}>
      <DynamicTemplate/>
    </ReduxStateProviderAndPersister>
  );
}

export default App;
