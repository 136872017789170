import { Configuration } from "../types/config";

const apiUrl = process.env.REACT_APP_API_URL as string
const imageUrl = process.env.REACT_APP_IMAGE_URL || "" as string
const stripeKey = process.env.REACT_APP_STRIPE_PK as string
const isProduction = process.env.NODE_ENV === 'production'

const configuration: Configuration = {
  apiUrl: apiUrl,
  imageUrl: imageUrl,
  stripeKey: stripeKey,
  isProduction
}

export default configuration;